export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.hook('app:chunkError', (error) => {
        console.log('Error in chunk:', error)
        window.location.reload()
    });

    nuxtApp.hook('app:error', (error) => {
        console.error("Global error:", error);

        if (error.message && error.message.includes("Couldn't resolve component") || error.message.includes("reading 'default'")) {
            console.warn("Component error.");
            window.location.reload();
        }
    });
});
