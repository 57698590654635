import {useUserStore} from "~/store/userStore.js";
import * as Sentry from "@sentry/nuxt";

export const useClientFetch = async (url, opts = {}) => {
    const config = useRuntimeConfig()

    if (!config || !config.public) {
        throw new Error('Runtime config is not available');
    }

    const userStore = useUserStore()

    let headers = {
        accept: 'application/json',
        ...opts?.headers,
        referer: config.public.baseURL,
        'Accept-Language': config.public.locale,
    }

    if (userStore.isAuthenticated) {
        headers['User-Token'] = `${userStore.token}`
    }

    return $fetch(url, {
        query: opts.query,
        body: opts.body,
        method: opts.method ?? 'GET',
        baseURL: config.public.API_URL,
        headers,
    })
}
