<script setup>
import Text from "~/components/atoms/Text.vue";
import Input from "~/components/atoms/Input.vue";
import Button from "~/components/atoms/Button.vue";
import ProductPrice from "~/components/molecules/ProductPrice.vue";
import ProductUnit from "~/components/molecules/ProductUnit.vue";
import Circle from "~/components/atoms/Circle.vue";
import CrossIcon from "~/components/icons/CrossIcon.vue";
import ReturnIcon from "~/components/icons/ReturnIcon.vue";

const props = defineProps({
  image: String,
  name: String,
  total: Number,
  unit: String,
  sku: String,
  quantity: Number,
  quantity_info: Number,
  max_qty: Number,
  unit_per_box: Number,
  is_sample: Boolean,
  link: String,
  input_name: {
    type: String,
    default: 'quantity'
  },
  noCounter: {
    type: Boolean,
    default: false
  },
  noRemove: {
    type: Boolean,
    default: false
  },
  isRestparti: {
    default: false
  }
});

const emits = defineEmits(['plus', 'minus', 'update', 'remove']);

let timer;
const removeTimeout = 2000;
const isRemoving = ref(false);

const removeItem = () => {
  emits('remove');
  return;
  if (timer) {
    isRemoving.value = false
    clearTimeout(timer)
    timer = null
  } else {
    isRemoving.value = true
    timer = setTimeout(() => {
      emits('remove');
    }, removeTimeout);
  }
};

// const increaseQuantity = () => {
//   if (qty.value < props.max_qty) {
//     qty.value++;
//     emits('update:quantity', qty.value);
//   }
// };
//
// const decreaseQuantity = () => {
//   if (qty.value > 0) {
//     qty.value--;
//     emits('update:quantity', qty.value);
//   }
// };
//
// const qty = ref(props.quantity);
//
// const change = (e) => {
//   if (props.max_qty) {
//     if (qty.value > props.max_qty) {
//       qty.value = props.max_qty
//     }
//   }
//   emits('update', e);
// };

const qty = computed(() => {
  return props.quantity;
});

const change = (e) => {
  emits('update', e);
};

</script>
<template>
  <div class="product row full-width g-16 stretch">
    <div class="image g-8" @click.stop>
      <img :src="props.image"/>
      <Text text-alignment="center" class="sku">{{ props.sku }}</Text>
    </div>
    <div v-if="!isRemoving" class="g-8 space-between" @click.stop.prevent>
      <div class="row full-width space-between flex-start">
        <nuxt-link class="name" :to="sku.startsWith('HCS') ? false : link" @click="$emit('click')">
          <Text class="product-name" tag="b">{{ props.name }}</Text>
        </nuxt-link>
        <div class="remove no-flex flex-end" v-if="!noRemove">
          <CrossIcon v-if="!isRemoving" width="22px" height="22px" @click.prevent.stop="removeItem" class="clickable"/>
        </div>
      </div>
      <slot/>
      <div class="row full-width">
        <div class="row g-8 the-same-gap full-width flex-end" :class="[unit]">
          <template v-if="!props.sku.startsWith('HCS')">
            <template v-if="noCounter">
              <Text v-if="!isRestparti">{{ props.quantity }} {{ props.unit === 'm2' ? 'box' : 'st' }}</Text>
            </template>
            <template v-else>
              <div v-if="props.quantity_info" class="row quantity-info g-4">
                <p>{{ props.quantity_info }} </p>
                <div class="row g-4 static" v-if="props.unit === 'm2' && props.unit_per_box">
                  <Text>box</Text>
                </div>
                <div class="row g-4" v-else-if="props.unit === 'st' && !props.is_sample && !isRestparti">
                  <Text>
                    <ProductUnit unit="st" :sup="true"/>
                  </Text>
                </div>
              </div>
<!--              <div class="row" v-if="!props.is_sample">-->
<!--&lt;!&ndash;                <Button type="minus" @click.stop="$emit('minus')"/>&ndash;&gt;-->
<!--                <Button type="minus" @click.stop="decreaseQuantity"/>-->
<!--                &lt;!&ndash;                <Input :name="input_name" width="32px" @change="change" type="number" min="1"&ndash;&gt;-->
<!--                &lt;!&ndash;                       :key="props.quantity"&ndash;&gt;-->
<!--                &lt;!&ndash;                       max="9999" v-model="qty"/>&ndash;&gt;-->
<!--                <Input :name="input_name" width="32px" @change="change" type="number" min="1"-->
<!--                       :key="props.quantity"-->
<!--                       max="9999" v-model="qty"/>-->
<!--&lt;!&ndash;                <Button type="plus" @click.stop="$emit('plus')"/>&ndash;&gt;-->
<!--                <Button type="plus" @click.stop="increaseQuantity" />-->
<!--              </div>-->

              <div class="row" v-if="!props.is_sample">
                <Button type="minus" @click.stop="$emit('minus')"/>
                <Input :name="input_name" width="32px" @change="change" type="number" min="1"
                       :key="props.quantity"
                       max="9999" v-model="qty"/>
                <Button type="plus" @click.stop="$emit('plus')"/>
              </div>
              <div v-else>
                <Text style="font-size: 12px;">Ett varuprov är ca 25x30 cm stort</Text>
              </div>
            </template>
            <template v-if="!noCounter">
              <div class="row g-4 static" v-if="props.unit === 'm2' && props.unit_per_box">
                <Text>box</Text>
                <Text v-if="!props.quantity_info" class="unit-calculator">=
                  {{ Math.round(props.unit_per_box * props.quantity * 100) / 100 }}
                  <ProductUnit unit="m2" :sup="true"/>
                </Text>
              </div>
              <div class="row g-4" v-else-if="props.unit === 'st' && !props.is_sample && !isRestparti">
                <Text>
                  <ProductUnit unit="st" :sup="true"/>
                </Text>
              </div>
            </template>
          </template>

          <div class="flex-end row left-auto">
            <template v-if="props.total">
              <ProductPrice :price="props.total"/>
            </template>
            <template v-else>
              <slot name="total"/>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div class="remove-callback flex-center" v-if="isRemoving">
      <Circle class="circle"/>

      <div class="row g-8" style="font-size: 12px;">
        <Text>Artikeln borttagen</Text>
        <Text tag="strong" class="underline clickable" @click.stop="removeItem">Ångra</Text>
      </div>

      <div class="return clickable" @click.stop="removeItem">
        <ReturnIcon/>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/mixins.scss";

.name {
  max-width: 90%;
}

.m2 {
  @include mobile {
    margin-bottom: 20px;
  }
}

.unit-calculator {
  @include mobile {
    position: absolute;
    left: 0;
    margin-top: 50px;
  }
}

.remove-callback {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);

  .circle {
    left: 0;
    top: 50%;
    transform: translateY(-105%) scale(.17);
  }

  .return {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.product {
  padding-bottom: 16px;
  border-bottom: 1px solid $border-grey;

  &:last-child {
    border-bottom: 0;
  }
}

.image {
  max-width: 71px;

  .sku {
    color: $black;
    font-size: 12px;
  }

  img {
    width: 100%;
  }
}

.product-name {
  max-width: 80%;
}

.price {
  font-size: 14px;
  font-weight: 500;
}

button {
  &.minus, &.plus {
    width: 26px;
    height: 26px;
    min-width: 26px;
    min-height: 26px;
    max-width: 26px;
    max-height: 26px;
  }
}

.remove {
}

.quantity-info {
  min-width: 54px;
}

</style>
