<script setup>
import {useCurrencyStore} from "~/store/currencyStore.js";
import Price from "~/components/atoms/Price.vue";
import ProductUnit from "~/components/molecules/ProductUnit.vue";

const store = useCurrencyStore();

const props = defineProps({
  price: [Number, String],
  unit: String,
  forceUnit: {
    type: Boolean,
    default: false
  },
  type: String,
  red: Boolean,
  class: String,
  light: {
    type: Boolean,
    default: false
  },
  lightUnit: {
    type: Boolean,
    default: false
  },
  line: {
    type: Boolean,
    default: false
  },
  digits: {
    type: Number,
    default: 2
  }
});

const {unit, type} = props;

</script>

<template>
  <div class="price row g-4" :class="[{old:type==='old'},{red}, props.class, {light:light,lightUnit}]">
    <Price :digits="digits" :price="parseFloat(props.price)"/>
    <template v-if="!line">
      <sup>
        <span>{{ store.currency }}</span>
        <template v-if="unit === 'm2' || forceUnit">/<ProductUnit :unit="unit" :sup="true"/></template>
      </sup>
    </template>
    <template v-else>
     <span>
        <span>{{ store.currency }}</span>
      <template v-if="unit === 'm2' || forceUnit">/<ProductUnit :unit="unit" :sup="true"/></template>
     </span>
    </template>

  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/mixins.scss";

.price {
  font-size: 16px;
  font-weight: 600;
  color: $space-black;

  &.light {
    font-weight: 400;
  }

  &.red {
    color: $red;
  }

  &.reset {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
  }

  &.old {
    color: $super-light-black;
    font-weight: 400;
    font-size: 14px;
    text-decoration: line-through;


    @include smd {
      font-size: 11px;
    }
  }

  sup {
    font-weight: 600;
  }

  &.light sup, &.lightUnit sup {
    font-weight: 400;
  }

  &.product {
    gap: 6px;

    &:not(.old) {
      font-size: 30px;

      @include smd {
        font-size: 20px;
      }

      sup {
        font-size: 17px;

        @include smd {
          font-size: 12px;
        }
      }
    }

    &.old {
      gap: 3px;

      sup {
        font-size: 14px;
      }
    }
  }
}
</style>
