import { default as _91_46_46_46slug_93PtRFzs2GDrMeta } from "/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_931XydJVi4XJMeta } from "/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/blogg/[...slug].vue?macro=true";
import { default as indexDmflXxzwyrMeta } from "/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/blogg/index.vue?macro=true";
import { default as index1BCByglIDXMeta } from "/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/checkout/index.vue?macro=true";
import { default as payment1POaC0jTdGMeta } from "/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/checkout/payment.vue?macro=true";
import { default as indexY9QSpsKPi9Meta } from "/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/checkout/thank-you/index.vue?macro=true";
import { default as index0x4oFEONNdMeta } from "/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/collections/index.vue?macro=true";
import { default as _91slug_935WF7Ua4ZSEMeta } from "/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/faq/[slug].vue?macro=true";
import { default as indexFUQl489rmOMeta } from "/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/hillceramic-rabattkoder/index.vue?macro=true";
import { default as indexkIzfW07SpmMeta } from "/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/index.vue?macro=true";
import { default as indexU1G6JkGrAqMeta } from "/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/login/index.vue?macro=true";
import { default as _91id_93vU9Ydz5ziYMeta } from "/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/qr-pr/[id].vue?macro=true";
import { default as _91_46_46_46slug_93NbWTkA2hlXMeta } from "/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/shop/[...slug].vue?macro=true";
import { default as indexGJf9buqJrQMeta } from "/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/track/index.vue?macro=true";
export default [
  {
    name: "slug___sv",
    path: "/:slug(.*)*",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/[...slug].vue")
  },
  {
    name: "slug___da",
    path: "/da/:slug(.*)*",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/[...slug].vue")
  },
  {
    name: "blogg-slug___sv",
    path: "/blogg/:slug(.*)*",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/blogg/[...slug].vue")
  },
  {
    name: "blogg-slug___da",
    path: "/da/blogg/:slug(.*)*",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/blogg/[...slug].vue")
  },
  {
    name: "blogg___sv",
    path: "/blogg",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/blogg/index.vue")
  },
  {
    name: "blogg___da",
    path: "/da/blogg",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/blogg/index.vue")
  },
  {
    name: "checkout___sv",
    path: "/checkout",
    meta: index1BCByglIDXMeta || {},
    component: () => import("/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/checkout/index.vue")
  },
  {
    name: "checkout___da",
    path: "/da/checkout",
    meta: index1BCByglIDXMeta || {},
    component: () => import("/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/checkout/index.vue")
  },
  {
    name: "checkout-payment___sv",
    path: "/checkout/payment",
    meta: payment1POaC0jTdGMeta || {},
    component: () => import("/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/checkout/payment.vue")
  },
  {
    name: "checkout-payment___da",
    path: "/da/checkout/payment",
    meta: payment1POaC0jTdGMeta || {},
    component: () => import("/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/checkout/payment.vue")
  },
  {
    name: "checkout-thank-you___sv",
    path: "/checkout/thank-you",
    meta: indexY9QSpsKPi9Meta || {},
    component: () => import("/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/checkout/thank-you/index.vue")
  },
  {
    name: "checkout-thank-you___da",
    path: "/da/checkout/thank-you",
    meta: indexY9QSpsKPi9Meta || {},
    component: () => import("/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/checkout/thank-you/index.vue")
  },
  {
    name: "collections___sv",
    path: "/kollektioner",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/collections/index.vue")
  },
  {
    name: "collections___da",
    path: "/da/samlinger",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/collections/index.vue")
  },
  {
    name: "faq-slug___sv",
    path: "/faq/:slug()",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/faq/[slug].vue")
  },
  {
    name: "faq-slug___da",
    path: "/da/faq/:slug()",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/faq/[slug].vue")
  },
  {
    name: "hillceramic-rabattkoder___sv",
    path: "/hillceramic-rabattkoder",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/hillceramic-rabattkoder/index.vue")
  },
  {
    name: "hillceramic-rabattkoder___da",
    path: "/da/hillceramic-rabattkoder",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/hillceramic-rabattkoder/index.vue")
  },
  {
    name: "index___sv",
    path: "/",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/index.vue")
  },
  {
    name: "index___da",
    path: "/da",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/index.vue")
  },
  {
    name: "login___sv",
    path: "/login",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/login/index.vue")
  },
  {
    name: "login___da",
    path: "/da/login",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/login/index.vue")
  },
  {
    name: "qr-pr-id___sv",
    path: "/qr-pr/:id()",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/qr-pr/[id].vue")
  },
  {
    name: "qr-pr-id___da",
    path: "/da/qr-pr/:id()",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/qr-pr/[id].vue")
  },
  {
    name: "shop-slug___sv",
    path: "/shop/:slug(.*)*",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/shop/[...slug].vue")
  },
  {
    name: "shop-slug___da",
    path: "/da/shop/:slug(.*)*",
    component: () => import("/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/shop/[...slug].vue")
  },
  {
    name: "track___sv",
    path: "/track",
    meta: indexGJf9buqJrQMeta || {},
    component: () => import("/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/track/index.vue")
  },
  {
    name: "track___da",
    path: "/da/track",
    meta: indexGJf9buqJrQMeta || {},
    component: () => import("/var/www/projects/hillceramic.se/releases/20241112101059Z/pages/track/index.vue")
  }
]