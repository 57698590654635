import {useUserStore} from "~/store/userStore.js";

export const useCustomFetch = async (url, opts = {}, lazy = false) => {
    const config = useRuntimeConfig()

    if (!config || !config.public) {
        throw new Error('Runtime config is not available');
    }

    const userStore = useUserStore()

    let headers = {
        accept: 'application/json',
        ...opts?.headers,
    }

    headers = {
        ...headers,
        // ...useRequestHeaders(['cookie']),
        referer: config.public.baseURL,
    }

    if (userStore.isAuthenticated) {
        headers['User-Token'] = `${userStore.token}`
    }

    headers['Accept-Language'] = config.public.locale

    // headers['Request-Path'] = route?.path

    if (lazy) {
        return $fetch(url, {
            query: opts.query,
            body: opts.body,
            method: opts.method ?? 'GET',
            baseURL: config.public.API_URL,
            headers,
        })
    } else {
        return useFetch(url, {
            baseURL: config.public.API_URL,
            headers,
            // credentials: 'include',
            ...opts,
        })
    }
}
